import React, { useState } from "react"
import Slider from "react-slick";
import { Container, Row, Col, Form } from "react-bootstrap"
import Img01 from "../../../images/douglas-no-image.png"
import Img001 from "../../../images/Property/property001.png"
import Img002 from "../../../images/Property/property002.png"
import ImgDesk01 from "../../../images/Property/desk-property01.png"
import ImgDesk02 from "../../../images/Property/desk-property002.png"
import ImgDesk03 from "../../../images/Property/desk-property003.png"
import { useStaticQuery, graphql, Link } from "gatsby";
import ScrollAnimation from 'react-animate-on-scroll';
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ModalTiny from "react-bootstrap/Modal"
import BookViewForm from "../../forms/book-a-viewing-form"
import _ from "lodash";
import "./Gallery.scss"

import { inViewOptions, contentItemStagger, contentItem, fadeInFromTop } from '../../utils/animation';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const settings = {
    speed: 800,
    infinite: true,
    arrows: true,
    mobileFirst: true,
    centerMode: false,
    swipeToSlide: true,
    dots: true,
    responsive: [
        {
            breakpoint: 3020,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1, 


            }
        },
        {
            breakpoint: 1300,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1, 

            }
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2, 
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1, 

            }
        }
    ]
};
const MoreDevelopments = (props) => {
    const data = useStaticQuery(graphql`
            query {
                allNewDevelopments(filter: {publish: {eq: true}}) {
                    nodes {
                      slug
                      id
                      development_title
                      price_prefix
                      price_range
                      max_price
                      min_price
                      short_description
                      images {
                        srcUrl
                        
                      }
                      imagetransforms {
                        Images_Transforms
                      }
                    }
                  }
            }
            `);
    const properties = data.allNewDevelopments.nodes;
    const [tabActive, setIsActive] = useState(false)
    const tabbing1 = () => setIsActive(false);
    const tabbing2 = () => setIsActive(true);
    const [iconOne, setOne] = useState(false);
    const [iconTwo, setTwo] = useState(false);
    const [iconThree, setThree] = useState(false);
    const [modalSendfrndformOpen, setSendfrndformOpen] = React.useState(false);
    const openSendfrndformModal = () => {
        setSendfrndformOpen(true);
    }
    const closeSendfrndformModal = () => {
        setSendfrndformOpen(false);
    }
    const handleFirst = () => {
        setOne(!iconOne);
    }

    const handleTwo = () => {
        setTwo(!iconTwo);
    }

    const handleThree = () => {
        setThree(!iconThree);
    }
    var developments = _.filter(properties, function (o) { return o.development_title != props.title; });
    // var developments = properties;

    return (
        <InView  {...inViewOptions}>
            {({ ref, inView }) => (
                <motion.section
                    className="property more-developments  custom-slider"
                    ref={ref}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    variants={contentItemStagger}

                >
                    <Container>
                        <Row>
                            <Col >
                                <Row>
                                    <Col lg={8}>
                                        <div className="title">
                                            <h2>More Developments</h2>
                                        </div>
                                    </Col>
                                    <Col lg={4}>
                                        <Link class="link-text desktop" href="/property/new-homes/for-sale/"><span>View all</span><i class="icon-arrow"></i></Link>
                                    </Col>
                                </Row>


                                <div className={tabActive ? 'd-none' : ''}>
                                    <Slider {...settings} >
                                        {developments && developments.slice(0, 10).map((item, k) => {
                                            var url = '/new-home-for-sale/' + item.slug + '-' + item.id

                                            let processedImages = JSON.stringify({});
                                            if (item?.imagetransforms?.Images_Transforms) {
                                                processedImages = item.imagetransforms.Images_Transforms;
                                            }
                                            return (
                                                <React.Fragment>
                                                    {item.development_title != props.title ?
                                                        <motion.div custom={k + 1} variants={fadeInFromTop} className="property-card-list " key={k}>
                                                            <div className="properties-wrapper ">
                                                                <div className="property-img img-zoom">
                                                                    <Link to={`${url}`} className="img-link" >
                                                                        <picture>
                                                                            {item.images && item.images.length > 0 ?
                                                                                // <React.Fragment>
                                                                                //     {item.images[0]?.url ?
                                                                                //         <ImageTransform
                                                                                //             imagesources={item.images[0]?.srcUrl}
                                                                                //             renderer="srcSet"
                                                                                //             imagename="new-developments.images.similar_properties"
                                                                                //             attr={{ alt: '', class: '' }}
                                                                                //             imagetransformresult={processedImages}
                                                                                //             id={item.id}
                                                                                //             ggfxName="FEED"
                                                                                //         /> : 
                                                                                <img src={item.images[0]?.srcUrl} className="image-fit-cover" alt="" />
                                                                                // }
                                                                                // </React.Fragment> 
                                                                                : <img src={Img01} alt="img" />
                                                                            }
                                                                        </picture>
                                                                    </Link>
                                                                </div>

                                                                <div className="properties-info">
                                                                    <h2 className="heading-info d-flex justify-content-between" >
                                                                        <Link to={`${url}`} className="img-link">{item.development_title}</Link>
                                                                    </h2>
                                                                    <span className="info-text">{item.short_description}</span>
                                                                    {/* {item.bedrooms && item.bedrooms > 1 ?
                                                                <span className="info-beds"><i className="icon-house-light-blue"></i>{item.Bedrooms} Bedrooms</span>
                                                                : item.bedrooms && item.bedrooms == 1 ?
                                                                    <span className="info-beds"><i className="icon-house-light-blue"></i>{item.Bedrooms} Bedroom</span>
                                                                    : <p className="empty-mb">&nbsp;</p>
                                                            } */}
                                                                    {item.min_price && item.max_price &&
                                                                        <React.Fragment>
                                                                            {item.min_price == item.max_price ?
                                                                                <span className="info-price range">
                                                                                    Price £{item.min_price.toLocaleString()}
                                                                                </span> :
                                                                                <span className="info-price range">
                                                                                    {item.price_prefix} £{item.min_price.toLocaleString()} - £{item.max_price.toLocaleString()}
                                                                                </span>
                                                                            }
                                                                        </React.Fragment>
                                                                    }
                                                                </div>
                                                            </div>
                                                            {item.images.length > 0 &&
                                                                <Link to={`${url}`} target="_blank">
                                                                    <div className="btn-group-wrap">
                                                                        <button className="btn-camera"><i className="icon-camera-gallery"></i> <span>1/{item.images.length}</span></button>

                                                                        {/* <button className="btn-play-circle"><i className="icon-play-circle"></i></button>
                                                                <button className="btn-flor"><i className="icon-flor"></i> </button> */}
                                                                    </div>
                                                                </Link>
                                                            }
                                                        </motion.div>
                                                        : ''}
                                                </React.Fragment>
                                            )
                                        })}

                                    </Slider>
                                </div>
                                <div className={!tabActive ? 'd-none' : ''}>
                                    <strong>Sorry, No result found</strong>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <ModalTiny show={modalSendfrndformOpen} onHide={closeSendfrndformModal} className="book-a-viewing-modal modal-main-form modal">
                        <ModalTiny.Header closeButton className="contact-close-btn">
                            <ModalTiny.Title className="w-100"><h2 className="text-center mb-0">Register your interest</h2></ModalTiny.Title>
                        </ModalTiny.Header>

                        <ModalTiny.Body className="event-section">
                            <BookViewForm property_img={props.image} page_url={props.shareurl} property_title={props.title} property_id={props.id} />
                        </ModalTiny.Body>

                    </ModalTiny>
                </motion.section>
            )}
        </InView>
    )
}
export default MoreDevelopments;
